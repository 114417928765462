import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Running from "../components/interests/running"

//TODO: this is taken from CV
//TODO: needs moderization
//TODO: unsure whether to include this, perhaps better to direct to another site
const InterestsPage = () => (
  <Layout>
    <SEO title="Résumé" keywords={[`cv`, `resume`, `life`, `personal`, `robertburrelldonkin`]}/>
    <h4>Interests</h4>
    <p>I play cricket. Nowadays, I only watch rugby league. I create open source software.</p>
    <p>And then there's the infamous going out, partying, listening to music and reading (just like
      everyone else does).</p>
    <h4>Hobbies</h4>
    <section>
      <h5>Technology</h5>
      <p>MacOS, FreeBSD, Linux (PowerPC, SUSE , Debian then Gentoo, Ubuntu and Mint), BradLUG, play around with various
        cryptography
        and security features on the Gentoo box. Now also have Windows mostly for DRM and so on. Vintage Suns for a
        while,
        now donated the the Natinoal Computer Museum at Bletchley. </p>
      <p>Gentoo is my main Linux at home. It's famous for compiling almost everything from source including kernals
        and so on. It's also one of the most customizable and individualisable distros out there. Gentoo is
        exceptionally
        frustrating at times. From time to time, an upgrade will break - sometimes at the most inconvenient moments.
        But it gives great control and freedom. The major distros necessarily centralise quality assurance and so on.
        This leads to religious ways as people fight over particular choices. Gentoo by its nature, whilst people are
        willing to invest time in maintaining particular approaches, all's fine.
      </p><p>
      It does lead to a lot of choices, and a lot more thought. There are times I need to be able to install a vanilla
      distro and know I'll be able to make it run without investing effort. I used to use Debian then Ubuntu now I tend
      to prefer Mint, but this choices tends to evolve with time. Ask me next time you see me!
    </p>
    </section>
    <section>
      <h5>Vintage Motorbikes</h5>
    </section>
    <section>
      <h5>Club Cricket in Clayton</h5>
      <p>The West Riding was once famed for its Cricket. Bradford Park Avenue was a glorious, proud
        test in its day </p>
      <p>As the work and the wealth of the City drained away, so the cricket declined.</p>
      <p>https://www.sportingintelligence.com/2012/04/05/analysis-yorkshire-supreme-in-english-cricket-but-thats-all-history-as-season-starts-050401/</p>
      <p>We played cricket in the street and at the local rec.</p>
      <p>The year I started Upper School, Salt cut the number of teams . </p>
      <p>
        The playing grounds are sold off to fund ever higher salaries for the people who run the City.
        When Bradford had wealth and influence, officers .
        Now Bradford and Bradfordian have no hope and no future, the people who have led the City into ruin
        feel themselves entitled to six-figures.
      </p>
      <p>Clayton in my youth had many churches, many pubs and many cricket fields. The pubs close, the churches become
        medrassahs and the fields built on. Before the mills closed forever, the loams and jobs shipped out to the third
        world,
        to be a club cricketer was something special. People would start in works teams, playing on a Wednesday.</p>
      <p>Bradford had a wealth of leagues, with like-minded people gathering together. The traditional Saturday leagues
        in
        Bradford have been in decline for years and several had closed in recent years. Sunday leagues such as
        Quaid E Azam are doing well.
      </p>
      <p>
        Bradford Mutual Sunday School League was started in by teams of friends who knew each other through church or
        chapel whose membership of more illustrious leagues had been rejected. After the second world war as traditional
        links with church and chapel loosened, the
        https://bmsscl.play-cricket.com/website/web_pages/174508
        Bradford Mutual Sunday School League began to admit other teams of friends with roots in other parts of the
        world and with different forms of worship. Teams of Patels, Sheikhs, Kashmiri, Hindus or Pakistanis
        who were not welcomed by other leagues. By the time I got involved, the formal religious links were dying out
        but the name remained.
        founded 1896
      </p><p>
      Cricket is one of the few activities that brings together all the communities in Bradford.
    </p>
      <p>
        When I started sub fielding for Tetley Street who played at Mayfield, standards were in gradual decline but had
        not begun to drop. I was fortunate enough to play with and against many fine players with a mix of religions and
        backgrounds.
        After 9-11, or watching my City burn in the valley bottom as a Kashmiri hit a brilliant ton.
      </p><p>
      I play for over a decade, first as Tetley Street (named after a Baptist Chapel) out of The Fleece and then as
      Mayfield
      CC (after merging with Grange) playing out of The Quarry.
      I've played third, second and first team. I enjoy opening the batting and love to play when the sun shines.
    </p><p>
      I fielded hard aggressive in the covers, fast and furious in the deep, close to the bat first short cover or
      leg then gully where I once ...? I made many friends. Most catches came at gully when a famous left-armed swing
      bowler played out his last seasons and a brawny, quick and bouncy lad at the other end.
    </p><p>
      When someone blew up the club house, I helped to rebuild it.
    </p><p>
      I bowled flighted slow right arm off spin drifting away from the bat and enjoyed a lot of stumpings, once helping
      my wicket-keeping to capture most dismissals in the league. I won ...?
    </p><p>
      I appeared in two cup finals but we lost both.
    </p><p>
      During my younger days, I often fielded close to the bat both silly point and silly mid on. Most notably
      when David Ross took a hat-trick.
    </p><p>
      I also enjoyed watching cricket, mostly notably watching Yorkshire a Championship at Scarborough.
    </p><p>
      I was fortunate enough to benefit from successful partnerships with two champion wicket keepers,
      martin spendelow and paul ackroyd. Unfortunately, with the decline in people willing to Umpire,
      stumpings became more rare.
    </p><p>
      Mayfield is one of those cricket ground that you only find in the pennines. An eccentric cricket loving
      capitalist carved a pitch from a hillside. The ground is large with a large flat but the outfield is
      really quite steep in places.
    </p>

      <p>I scored, made sandwiches and helped out with the juniors.</p>
      <p>Trophies...?</p>
      <p>Playing at the Mayfield</p>
      <p>
        The mutual sunday school league has had a traditional of rewarding fielding and many clubs
      </p>
      <p>
        Also played in the evening leagure
      </p>
      <p>
        2005 first XI Browling Trophy
        1998, 2002 Second Xi Fielding Trophy
        Broadbent cup, sunners up 2005
        Clubman of the Year 1999, 2002
        1998 evening league fielding award
        2000 Fielding Award
        Sir JAmes Robert Cup Runners up 2003
        Runner up league fielding point 2000
      </p><p>
      Helped out with ground, preparing wickets and so on.
    </p><p>
      First XI Second XI Third XI
      2007 5th Group B 7th Group C
      2006 5th Group B 1st Group C
      2005 8th Group A 5th Group C
      2004 4th Group A 6th Group C
      2003 3rd (group A) 3rd Group B
      2001 4th Group A 10th Group B 7th Group C
      2000 2nd Group A 9th Group B 5th Group C
      Tetley Street
      1998 8th Group B 10th Group C

    </p><p>
      2006 partnership of 120 with carl bartley
      2001 114 partnership with Omar Batti, half century
    </p>
      https://www.closedpubs.co.uk/yorkshire/bradford_bd14_quarryarms.html
    </section>
    <Running/>
    <section>
      <h5>Local Music Scene</h5>
      <p>
        To recover from the injury, I needed to cut out the drinking but in the 90s and 00s I enjoyed
        going out onto the local scene in Bradford with my mate. Especially to the local pub music scene and to follow
        local bands. There were lots of pubs.
      </p>
      <p>Venues like The Empress, Rios, The Black Swan, Market Tavern, New Pack Horse, One in 12 club, The Branch. The
        Lord Clyde,
        Mannville Arms
        POlish Ex Servicemens Club, etc...?
        https://www.closedpubs.co.uk/yorkshire/bradford_bd7_mannvillearms.html
        The Black Swan The Black Swan
        4.3 (27) · Live Music
        150 Thornton Rd
        Permanently closed
        Beehive
        Almost all gone now. And the people.
        https://www.closedpubs.co.uk/yorkshire/bradford_bd1_blackswan.html
        https://www.closedpubs.co.uk/yorkshire/bradford_bd1_commercialinn.html
        https://www.closedpubs.co.uk/yorkshire/bradford_bd1_newpackhorse.html
        ...?</p>
    </section>
    <section>
      <h5>Think C and MacOS 1994-1998</h5>
      <p>
        Think C is an object-oriented C dialect based on objects. It's specialised for MacOS X.
        I taught myself enough and wrote a number of small programmes including a game which I demoed in my interview
        for the Trainer Programmer position at Unipro.
      </p><p>
      Macs in those days used cooperative multi-tasking. Windows jumped straight to preemptive multi-tasking
      which has may advantages but developing a cooperative multi-tasking programme forces the programmer to
      much more explicitly consider and address how multi-tasking is intended to work. Coorperative multi-tasking on the
      Mac was a lot more deterministic, with fewer flaky corner cases. I think that this experience really helped
      me.
    </p>
    </section>
    <section>
      <h5>Java and Linux 1998 onwards</h5>
      <p>
        Soon after starting as a Trainee Programmer, I moved from Think C to Java at home. Think C had a relatively
        small number of foundation classes but even that took me some time to become familiar with. The prospect
        of learning C foundation class for Unix, MacOS and Windows did not appeal to me.
      </p><p>
      So I spent some time learning Java and the Swing libraries.
    </p><p>
      I was also excited by the
      prospect of web and internet applications. In order to experiment, I set up PowerPC Linux on an iMac and ran
      Apache-JServ. Which led me to Tomcat, ECS and to start contributing to Apache.
    </p>
    </section>
    <section>
      <h5>The Playhouse</h5>
      <p>
        Performing is quite a Yorkshire tradition. Not namby, pamby southern acting but guts and glory stuff.
        Northern. Think Sean Bean.
        My Dad didn't have a mining background but grew up in a mining area. Read Alan Bennett.
      </p><p>
      And a grand traditions of little threatres and play houses. Amongst the Northern Working Class, leisure was a
      serious matter. Hard work and effort. Might just be putting on a little bit.
    </p><p>
      The traditional continues today, with Gilbert and Sullivan societies playing our little theatres.
    </p><p>
      My Dad packed me off to the Bradford Playhouse to learn to act and speak. At least until my music consumed so
      many weekends that I had no time and they burnt our Playhouse down. 
    </p>
    </section>
  </Layout>
)

export default InterestsPage
