import React from "react"

const Running = () => (
  <section id='running'>
    <h5>Running</h5>
    <p>
      To recover from my injury, I needed to cut high risk activities out of my life. No more cricket, rugby,
      motorbikes
      or music but an opportunity to take up some activies which I might not have found the time for before.
    </p><p>
    I tried driving a saloon car but the seating position triggered sensations which made it difficult to concentrate
    on the road. Replacing the classic Kawasaki with a car didn't hold much appeal. So I was reliant on public
    transport.
  </p><p>
    Commuting from Clayton became every more a chore, having to catch earlier and earlier buses which often ran very
    late.
    Though terribly overcrowded, the trains started to run more reliably.
  </p><p>
    I like to deliver my promises. It feels good to
    arrive at work on time. It's all down hill from Clayton to Bradford Train Stations, about three and a half miles
    but
    drops a little less than 400ft from the rim down into the bottom of the bowl. I wondered whether I'd be able to
    beat the
    bus - and yes, turned out that I could run into town with the pack on my back quicker than the bus. More reliable
    too.
    I ran in every weather condition, sun or snow, rain or fair and found I enjoyed running.
  </p><p>
    My dad's side is a family of runners, with my uncle and great-granddad both fine harriers. The exception are those
    like myself and my dad with exercise induced asthma. I didn't enjoy running in the cold at school but I found
    that running every day eased the exercise induced asthma. I had the shots when youngster and haven't carried an
    inhaler for years but this came back to bite me when I tried to run my first marathon in 3 hours. I'd trained in
    a long, warm summer but York was cold, clammy and misty in October. I was feeling good running with a pacing group
    until I hit the only rise on the course at around 9 miles. My rate of effort was too high and kicked off the
    exercise induced asthma. My particular sort of asthma is quite manageable - it's hertidary and my Dad didn't know
    of anyone in his side who'd died from it. (Mostly industrial injuries, as was typical for the working class but
    hopefully that isn't interited). When I feel my chest tighten and my breath wheeze, I just need to stop and relax
    until the reaction subsides and I'm able to breath more freely. I now have an inhaler for occasional use.
  </p><p>
    When I bought a home in Saltaire right next to the train tracks, I continued to run every morning before work.
    It turned out that a friend running club met locally - Saltaire Striders. After being encouraged to give them a
    go,
    I did and found I really enjoyed the cameradery and social running. I usually like to be doing something. I need
    to learn to chill out and relax more. Social running is great - keeps my body moving and sees the scenary whilst
    catching up with my mates.
  </p><p>
    One day, I'd like to match . The great thing about
    technology is good for age. Even if you take up running later in life, you can still match yourself good for age.
  </p><p>
    I really enjoy the paired running races organised by clubs around the West Riding. Like fell running, these have
    a more traditional feel. City centre road racing is all glamour, glitz and mass participation.
    It's amazing how everyone around here seems to be getting out and about, taking up running.
    Raising money for charity.
  </p><p>
    These older races are for teams of club runners of every ability, and often see interesting parts of the
    locality. Some highlights were running the first leg of the Bradford Millienium Relay up over the Pack Horse
    Bridge following a trade route which was old at the conquest, up and up past wonderful hidden Howenden Falls
    under the old railway viaduct
  </p><p>
    Urgent short contracts are less predicatable. I structure my efforts around the needs of the end clients.
    For example, when delivery software for the US market I often decided to split the hours and locations to
    maximise the business value we were able to deliver to the end client. This lifestyle does not really allow
    the sort of training schedule that allows racing.
  </p><p>
    Here in England, even for people with clear clinic needs and at high risk of complications, the NHS now limits
    entitlement to antibiotics. Here in Bradford, most people need to be admitted to A+E before they can get
    antibiotics.
    I caught a nasty cough keeping an eye on the key Christmas racing for my employer when the heating failed.
    This led to a chest infection. In the past, as an asthmatic with a family history of complications, I'd always
    been prescribed strong antibiotics. After waiting 12 weeks to see a GP, I found myself with no entitlement
    to the antibiotics which would have cleared it up.
    This didn't stop me working but in due course gastro complications came along. I started to have serious weight
    loss
    and problems.
    Six months after the initial cough, a GP was willing to run tests on the gastro which were high enough.
    The NICE guidelines for entitlement to gastro diagnosis are , and Bradford struggles to meet demand.
  </p><p>
    I talked to my relatives and did elimination dieting.
  </p><p>
    When my wait was over and my turn came to see an NHS consultant, cutting out gluten was working well for me
    and since I'd limited entitlement to diagnosis the consultant concluded that it most probably a complication
    arising from an infection which would have responded to antibiotic treatment had I been entitled.
  </p><p>
    Since that time, I've done some research. Antibiotics are now easily available from dealers on the street or
    via a private prescription. The NHS recommends lifestyle changes and vacinnation. Vaccination isn't an options
    for me on the NHS since I have family history of problems with egg cultured vacinees. I tried the radical
    lifestyle change, moving from permanent employment to a mixed short contracting model, cut down on hist risk
    acivities
    and stay wlon over the winter months. Yes, I haven't caught a cough since they but it's means putting my life on
    hold and
    is no longer sustainable given the move to a high (50%) flat rate of tax on contracts.
  </p><p>
    Ironically enough, though the NHS weren't willing to tackle the chest infection or the chronix weght loss unless
    I'd
    needed to be taken in A+E, they did insist that I attend a healthy life style clinic. And yes, serious gastro
    cmioplication are an excellent way to keep the weight down.
  </p><p>
    One of the high risk activies was long distance running. It took me a while but I've been able to keep
    the weight on. I have troubles getting enough carb for a while. Running on a high fat, high protein diet
    isn't ideal for long distance stuff or racing. So I cut that out.
  </p><p>
    For the last few years, I've been doing more training at the track and running on the fells and trails for fun.
  </p><p>
    Marshalling is important. Unless experienced club runners are willing to marshall, mass participation events
    simply cannot happen, whether big or small. I appreciated the unsung efforts of people when I started out
    and felt it only right that I give back to the community, helping to make these events happen. I've marshalled
    several 5 and 10ks and one half-marathon.
  </p><p>
    I'm current an member of England Athletics, number???
    You might be able to see my
    https://www.runbritainrankings.com/runners/profile.aspx?athleteid=669595
    https://www.thepowerof10.info/athletes/profile.aspx?athleteid=669595
  </p>
    <p>
      Power of 10
      <pre>
        2017 V40 Saltaire Striders
        Event	Perf				Pos				Venue	Meeting	Date
        10KMT	48:49				39			10	Hawksworth	Over the Odda 10K	6 May 17
        2016 V40 Saltaire Striders
        Event	Perf				Pos				Venue	Meeting	Date
        10KMT	44:47				49			7	Guiseley	Guiseley Gallop 10K	27 Mar 16
        10KMT	47:26				24			3	Hawksworth	Over the Odda 10K	14 May 16
        HM	91:29			91:14	209			25	Leeds	Plusnet Leeds Half Marathon	8 May 16
        HMMT	1:49:09				31			6	Threshfield	Wharfedale Half Marathon	4 Jun 16
        ZMTL	1:40:20				17	L1		2	Bingley	Bradford Millennium Way Relay	26 Jun 16
        ZXC	36:51				162			21	Roundhay	PECO League	14 Feb 16
        2014 V40 Saltaire Striders
        Event	Perf				Pos				Venue	Meeting	Date
        parkrun	19:30				10				Bradford	Bradford parkrun # 218	26 Jul 14
        parkrun	19:34				7				Bradford	Bradford parkrun # 216	12 Jul 14
        parkrun	20:18				13				Bradford	Bradford parkrun # 212	14 Jun 14
        10K	40:43			40:10	127			20	Leeds	Leeds 10K	20 Jul 14
        10K	41:42			41:27	49			8	Burnley	Pennine 10K	22 Jun 14
        10K	42:34			41:09	130			49	Hull	Hull 10K	8 Jun 14
        10K	46:34			46:28	310			81	Bradford	Epilepsy Action Bradford 10K	16 Mar 14
        HM	88:01			87:33	87			20	Sherburn in Elemt	Vale of York Half Marathon	7 Sep 14
        HM	98:39			97:23	527			76	Leeds	Leeds Half Marathon	11 May 14
        ZXC	NT				236			52	Leeds	PECO League	30 Nov 14
          </pre>
      <pre>
          UK Rankings (Age)
Event		AG	Year	Rank
10K		V40	2014	1494
HM		V40	2014	1166
HM		V40	2016	1770
      </pre>
      <p>
        Run Britian
        Lowest handicap was 5.3 in 2014
        <pre>
        Event	Year	Time	In UK
        parkrun	2014	19:30	5.4%
        10K	2014	40:10	6.5%
        HM	2014	87:33	4.2%
        10K	2016	44:47	14.3%
        HM	2016	91:14	6.1%
        10K	2017	48:49	24.5%
        </pre>
        <pre>

Performances
Event  ↓	Time  ↓	Race  ↓	SSS  ↓	vSSS  ↓	Date  ↓	   ↓
10K	48:49	Over the Odda 10K	6.0	2.7	6 May 17	+
HM	91:14	Plusnet Leeds Half Marathon	2.0	-0.3	8 May 16	+
10K	44:47	Guiseley Gallop 10K	6.0	-1.0	27 Mar 16	+
10K	47:26	Over the Odda 10K	6.0	1.5	14 May 16	+
HM	1:49:09	Wharfedale Half Marathon	6.0	3.3	4 Jun 16	+
HM	87:33	Vale of York Half Marathon	0.7	-1.2	7 Sep 14
parkrun	19:34	Bradford parkrun # 216	3.0	-3.4	12 Jul 14
parkrun	19:30	Bradford parkrun # 218	2.5	-2.1	26 Jul 14
10K	40:10	Leeds 10K	1.5	-2.1	20 Jul 14
10K	46:28	Epilepsy Action Bradford 10K	0.5	0.0	16 Mar 14
HM	97:23	Leeds Half Marathon	1.3	-3.5	11 May 14
10K	41:09	Hull 10K	0.7	-3.6	8 Jun 14
parkrun	20:18	Bradford parkrun # 212	2.0	-0.9	14 Jun 14
10K	41:27	Pennine 10K	1.6	-1.3	22 Jun 14

Order By:
Other Performances
Event  ↓	Time  ↓	Race  ↓	Date  ↓
XC	NT	PECO League	30 Nov 14
XC	36:51	PECO League	14 Feb 16
MT	1:40:20	Bradford Millennium Way Relay	26 Jun 16

          What are these?

View national ladder leaderboards
Event Rankings
Event	Year	Time	In UK
parkrun	2014	19:30	5.4%
10K	2014	40:10	6.5%
HM	2014	87:33	4.2%
10K	2016	44:47	14.3%
HM	2016	91:14	6.1%
10K	2017	48:49	24.5%

        </pre>
      </p>
    </p>
    <section>
      <h6>Cross Bay Challenge</h6>
      https://northernrunningguide.com/assets/2016/cross-bay-challenge/results/results-run.pdf
      55Robert BurrellDonkinMVM 01:55:36.35 20(overall) 12(cat) 20(sex) 20 minutes slower than first.
      <p>
        Did a half marathon across Morecambe Bay. We watched the tide go, ran down, across the river then out following
        the tide out towards the Irish Sea. After an hour the land vanished all around and we just had the mud, the wind
        and each other. Time vanished. Isolated, just the group and the elements. Oddly enough, had the same feeling
        after running the strip for 30 minutes. The good news about the strip is that I didn't fall over into the claggy
        black mud in front of the crowd just before the grand finish, walking into the finish dripping with smelly black
        gunk...
      </p>
      <h6>Wharfedale Half</h6>
      https://www.runbritainrankings.com/results/results.aspx?meetingid=156589
      31 1:49:09 Robert Burrell Donkin V40 M Saltaire Striders 91:14 87:33 25.1
    </section>
    <p>

    </p>
  </section>
)

export default Running
